<template>
     <div id="vueSlickCarousel">
         <VueSlickCarousel class="mainImg" ref="c1" v-bind="mainSettings" :asNavFor="$refs.c2" :focusOnSelect="true" @init="mainInit">
          <div v-if="refImgList.length == 0" class="list"><img src="../assets/upload_blank.png" alt=""></div>
          <div v-else v-for="(item, i) in this.refImgList" :key="i" class="list">
              <img v-lazy="require('../assets/handsRef/'+item.file)"/>
            </div>
        </VueSlickCarousel>
        <VueSlickCarousel class="subImg" ref="c2" :asNavFor="$refs.c1" v-bind="subSettings" :focusOnSelect="true" @init="subInit">
          <div v-if="refImgList.length == 0"  class="list"><span class="year">세</span><img src="../assets/upload_blank.png" alt=""></div>
          <div v-else v-for="(item, i) in this.refImgList" :key="i" class="list">
            <span class="year">{{item.ageYear}}세 {{item.ageMonth}}개월</span>
            <img v-lazy="require('../assets/handsRef/'+item.file)"/>
          </div>
        </VueSlickCarousel>
      </div>
</template>
<script>
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import VueSlickCarousel from 'vue-slick-carousel'
import API_REF from '../API/reading/referenceList'
export default {
    components: { VueSlickCarousel },
    props: {
        xrayMap:{
            default() {
                return {
                  rsltUrl: '',
                  modNm: '',
                  modThumbNm: '',
                  pictureDt: '',
                  handsType: '',
                  atTimeAge: 0,
                  boneAge: 0,
                };
            },
      }
    },
    data() {
        return {
          refImgList: [],
          targetInfo: {},
          mainSettings: {
            "lazyLoad": "ondemand",
            initialSlide:0
          },
          subSettings: {
            "lazyLoad": "ondemand",
            "slidesToShow":3,
            "centerMode": true,
            initialSlide: 0
          }
        };
    },
    computed: {},
    watch: {
    },
    created() {},
    async mounted() {
      this.setTargetData()
      await this.initData(this.targetInfo.sex);
    },
    destroyed() {},
    methods: {
      setTargetData() {
        let patient = localStorage.getItem('patientInfo');
        let target = null;
        if (!patient) {
          target = localStorage.getItem('targetInfo');
          if (target) this.targetInfo = JSON.parse(target);
        } else {
          this.targetInfo = JSON.parse(patient);
        }
      },
      async initData(sex) {
        if (this.isGettingItems) {
          return;
        }
        this.isGettingItems = true;
        let refRes = await API_REF.request(sex);
        if (refRes.isSuccess) {
          this.refImgList = refRes.list;
        }
        this.isGettingItems = false;
      },
      setSelectedRef() {
        let result = 0
        if (this.xrayMap && this.refImgList.length > 0) {
          let boneAge = this.xrayMap.boneAge;
          let near = this.refImgList[0].age;
          let minDiff = Math.abs(boneAge - near);
          for (let i = 0; i < this.refImgList.length; i++) {
            const diff = Math.abs(boneAge - this.refImgList[i].age);
            if (diff < minDiff) {
              near = this.refImgList[i].age;
              minDiff = diff;
              result = i;
            }
          }
        }
        
        
        return result;
      },
      mainInit() {
        this.mainSettings.initialSlide = this.setSelectedRef()
      },
      subInit() {
        this.subSettings.initialSlide = this.setSelectedRef()
      }
    }
};
</script>

<style lang="scss" scoped>
@import "../sass/app.scss";


</style>